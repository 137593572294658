.root {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.outerContainer {
    position: relative;
    width: 900px;
    width: min(900px, 90vh);
    padding: 10px;
}

.innerContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container {
    position: relative;
    display: block;
    background: white;
    border: grey thin solid;
    max-width: 900px;
    width: 100%;
    height: 0px;
    padding-top: 100%;
}

.loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.5s linear;
}

.image {
    opacity: 0;
    transition: opacity 500ms ease-out;
}

.show {
    opacity: 1;
}


.fill {
    background: grey;
    width: 100%;
    height: 100%;
    animation: fadeIn 1s infinite alternate ease-in-out;
}

@keyframes fadeIn {
    from { opacity: 0.25; }
    to { opacity: 0.65; }
}

.textBlock {
    padding: 0 20px;
}

.textBlock * {
    overflow-wrap: break-word;
    /*word-break: break-all;*/
    hyphens: auto;
}
.hidden {
    visibility: hidden;
}

.attachmentOverlay {
    position: absolute;
    text-align: center;
}

.pill {
    border-radius: 10px;
    color: white;
    background: lightgray;
    text-transform: uppercase;
    padding: 10px;
}

.frame {
    position: absolute;
}

.past {
    transform: translateX(calc(min(900px, 90vh) * -1.2));
}

.future {
    transform: translateX(calc(min(900px, 90vh) * 1.2));
}
