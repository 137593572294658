
.outer {
    width: 100%;
    height: 100%;
}
.inner {
    border: 1px transparent solid;
    box-sizing: border-box;
}

.hidden {
    visibility: hidden;
}
